/* @flow */

import * as React from 'react'
import { ProductTemplate } from '../templates'

type Props = {
  location: {
    href: string,
    state: Object,
  },
}

export default function Form(props: Props) {
  const { location } = props
  return (
    <ProductTemplate
      url={location.href}
      media="illu_product-business.png"
      title="Optez pour une facturation automatique et simplifiée"
      name="Business"
      label="Business"
      description="Développez vos ventes aux professionnels grâce à notre logiciel de facturation. Créez des devis et factures personnalisés et gérez les paiements sereinement depuis la caisse enregistreuse Wino et le tableau de bord."
      body={[
        {
          name: 'features',
          items: [
            {
              media: 'icon_product-business_createinvoice.png',
              title: 'Création des factures',
              content:
                'Créez vos devis, bons de livraison et factures depuis la caisse connectée sur iPad et le tableau de bord',
            },
            {
              media: 'icon_product-business_clientmonitoring.png',
              title: 'Suivi par client',
              content: 'Retrouvez tous les documents commerciaux et informations de vos contacts',
            },
            {
              media: 'icon_product-business_paiementmonitoring.png',
              title: 'Contrôle des paiements',
              content: "Suivez l'état d'avancement des paiements et éditez vos exports comptables",
            },
            {
              media: 'icon_product-business_purchasemanagement.png',
              title: 'Paiement en ligne des factures',
              content:
                'Mettez en place un système de règlement à distance des factures grâce à WinoPay',
            },
          ],
        },
        {
          name: 'calloutCards',
          items: [
            {
              decoration: 'mujeres',
              media: 'screenshot_business_createinvoice.png',
              alt: "création d'une facture",
              label: 'Création des factures',
              title: 'Éditez vos documents commerciaux professionnels en toute simplicité',
              content:
                'Créez tout type de documents pour la vente aux professionnels : devis, facture, facture pro forma, avoir, bon de livraison. Ils sont personnalisables aux couleurs de votre charte graphique et avec votre logo depuis les paramètres de la caisse tactile. Transformez vos devis et bons de livraison en factures ou bien vos tickets de caisse en factures. Tout est fait pour décupler votre productivité.',
              feedback: {
                profile: 'photo_product-dashboard_claudelecaviste.jpg',
                name: 'Claude Le Gall',
                company: 'Claude le Caviste',
                content:
                  'Une entreprise m’a acheté du vin pour une soirée. Je n’ai mis que quelques instants à créer sa facture et aussi immédiatement il l’avait sur son portable, le top !',
              },
            },
            {
              decoration: 'gypsyeyes',
              media: 'screenshot_business_clientmonitoring.png',
              alt: 'suivi des clienst',
              label: 'Suivi par client',
              title: 'Gagnez du temps quotidiennement pour le suivi de vos client',
              content:
                "Au sein d'un répertoire intégré à la caisse enregistreuse Wino et regroupant tous vos clients professionnels, accédez aisément à toutes les informations concernant chacun d'eux : ses coordonnées, les documents commerciaux avec différents statuts selon leur état de paiement, l'ensemble des produits qu'il vous achète. Enfin, envoyez-lui les factures par mail directement depuis le logiciel de caisse Wino. La vente aux professionnels n'a jamais été aussi simple ! ",
            },
          ],
        },
        {
          name: 'informationInsert',
          items: [
            {
              media: 'security',
              title: 'Disponible partout et sécurisé',
              content: `Toutes vos données sont stockées en Europe et sécurisées. Vous pouvez y accéder tout le temps et partout depuis votre iPad, votre mobile, et votre ordinateur sur le logiciel de caisse et le tableau de bord. Il est également possible d'avoir accès à vos données depuis la caisse sur iPad hors connexion.`,
            },
            {
              media: 'compliance',
              title: 'Conforme à la loi 2018',
              content:
                'Le module de facturation Wino a été développé pour répondre aux exigences de conformité de la loi de finances 2018. Nous prenons soin de votre comptabilité et du suivi de votre TVA.',
              linkTo: '/logiciel-de-caisse-enregistreuse-obligatoire-en-2018',
              linkText: 'Lire nos articles sur la loi de finances 2018',
            },
          ],
        },
        {
          name: 'calloutCards',
          items: [
            {
              decoration: 'quarantine',
              media: 'screenshot_business_paiementmonitoring.png',
              alt: 'suivi des paiements',
              label: 'Suivi des paiements',
              title: "Assurez-vous d'être payé en temps et en heure",
              content:
                "Accédez à la liste des documents commerciaux dans un historique centralisé depuis la caisse tactile. Visualisez en un coup d'oeil l'état de vos factures payées, en attente de paiement et en retard. Mettez à jour en un clic les statuts de paiement.  Exportez pour votre comptable les données mensuelles de facturation : totaux et TVA. Avec Wino, vous suivrez vos factures à la trace !",
            },
            {
              decoration: 'alwadood',
              media: 'screenshot_business_epaiement.png',
              alt: 'gestion des achats',
              label: 'Paiement en ligne des factures',
              title: 'Digitalisez le règlement des factures',
              content: `Permettez à vos clients de vous payer en ligne grâce au service WinoPay. Avec un service de paiement directement intégré à votre solution de facturation, vous réduisez les retards de paiement et également le suivi des paiements des factures. En effet, une facture réglée avec WinoPay est automatiquement reconnue comme payée. La solution de paiement est fiable, conforme et sécurisée. Enfin, l'accès au service est gratuit et la commission sur les paiements est l'une des plus basses du marché.`,
            },
          ],
        },
      ]}
    />
  )
}
